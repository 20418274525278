@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.MenuContainer{
  position: 'absolute';
  top: 0;
  left: 0;
  zIndex: '99';
  opacity: 0.9;
  display:'flex';
  alignItems:'center';
  background: 'black';
  width: '100%';
  color: 'white';
}

.MenuContainerBody{  display: 'flex';
  flexDirection: 'column';
  alignItems: 'center';
  width: '100vw';
  height: '100vh';
  transition: 'filter 0.5s ease';
}

.Menu{
  position: 'absolute';
  top: 0;
  left: 0;
  height: 0;
  width: '100vw';
  display: 'flex';
  flexDirection: 'column';
  background: 'black';
  opacity: 0.95;
  color: '#fafafa';
  transition: 'height 0.3s ease';
  zIndex: 2;
}

.MenuButton{
  height: '32px';
  width: '32px';
  display:'flex';
  flexDirection: 'column';
  justifyContent: 'center';
  alignItems: 'center';
  cursor: 'pointer';
  padding: '4px';
  margin: 12px 0;
}
