.Detail{
  background-color: black;
  bottom: 0;
  /* width: calc(100% - 24px); */
  /* position: fixed; */
  /* padding-bottom: 1vh; */

}

.Detail h4{
  margin: 4px 0;
}

.Detail > div {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
