.App {
  margin: 0 0;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: black;
  color: white;
}

a{
  color: white;
  text-decoration: none;
}

a:hover{
  color: #FAFAFA;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 12px;
  top: 8px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed;
  width: 36px !important;
  height: 36px !important;
  left: 12px;
  top: 8px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(0, 0, 0);
  overflow: hidden;
}
