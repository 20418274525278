.Map .Marker{
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  width: 32px;
  height: 48px;
  text-align: center;
  transform: translate(-50%, -100%);
  cursor: pointer;
  &:hover {
    z-index: 1;
  }
}

.Map .MyLocation{
  width: 14px;
  height: 14px;

  background-color: red;
  border-radius: 100%;
  border: 1px solid red;
  color: black;
  animation: LocationAnimation;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  /* animation-timing-function: ease-in-out; */
  animation-direction: alternate;
}

.Map .MyLocation span{
  position: absolute;
}

@keyframes LocationAnimation {
  from {opacity: 1.0;}
  to {opacity: 0.6;}
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
